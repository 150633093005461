<template>
  <div class="about">
    <div class="banner center">
        <div style="margin-top: -50px">
          <img width="200" alt="Seguros Viagem logo" src="../assets/ea-logo.png">
        </div>
    </div>


    <b-container id="content">
      <div class="row">
        <div class="col-md-8 mx-auto p-md-5 p-3">
        
          <h3 class="title-content">Europ Assistance Portugal</h3>
          <h4 class="subtitle-content">Há {{ age }} anos a cuidar de quem confia em nós</h4>
          <p>O Grupo Europ Assistance em Portugal opera através da Europ Assistance S. A. – Sucursal em Portugal, comercializando os seus produtos de assistência em viagem através desta entidade.</p>

          <p>Em Portugal desde 1993, a Europ Assistance assume-se pioneira no conceito de assistência.</p>

          <p>O seu portfolio abrange as áreas de negócio automóvel, viagem, saúde e lar & família, apostando 
          no desenvolvimento de serviços inovadores, de assistência personalizada, focados no dia-a-dia 
          e na mobilidade dos clientes, que designamos por care services.</p>

          <p>Há mais de um milhão de portugueses que confiam em nós. Na nossa disponibilidade, eficiência 
          e capacidade de resolver problemas</p>

          <p>A equipa é constituída por mais de 400 profissionais, qualificados e conhecedores do mercado 
          de assistência, com um “call center” em operação durante todo o dia e ao longo de todo o ano.</p> 
          <p>A dimensão da sua atividade faz com que seja necessário um desenvolvimento permanente de uma rede diversa e especializada que engloba perto de três mil parceiros.</p>

          <p>A nossa missão, “from distress to relief, anytime, anywhere”, tem como objectivo, apoiar os clientes em todas as fases da sua vida, desde as situações de emergência às necessidades básicas do dia-a-dia.</p>

          <h4>Informação Legal</h4>
          <p>A Europ Assistance S.A. - Sucursal em Portugal tem morada na Av. Columbano Bordalo Pinheiro, n.º 75, 10.º andar, 1070-061 Lisboa e encontra-se registada na C.R.C. sob o número uníco de matrícula e pessoa colectiva NIPC 980667976. Site institucional: <a target="_blank" href="https://www.europ-assistance.pt"> www.europ-assistance.pt</a></p>
       
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import PublicLayout from "@/layouts/PublicLayout.vue";

export default {
  name: "Quem-somos",
  created() {
    this.$emit("update:layout", PublicLayout);
  },
  data(){
    return {
      age : 0
    }
  },
  mounted(){
    this.age = this.calculateAge( new Date(`1993-01-01`) )
  },
  methods:{
    calculateAge: function(birthday) { // birthday is a date
      var ageDifMs = Date.now() - birthday;
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    }
  }
};
</script>

<style scoped>

</style>